<template>
  <div class="loanDetail">
    <!-- 贷款产品基本信息 -->
    <div class="basicInformation">
      <!-- 图片 -->
      <div class="Image">
        <!-- <img :src="propsList.logoUrl" alt="Loan Logo" /> -->
        <img :src="param1.logoUrl" alt="图片已损坏" />
      </div>
      <!-- 基本信息 -->
      <div class="content">
        <!-- 标题 -->
        <div class="loansTitle">
          <span class="title">{{ propsList.title }}</span>
          <div class="approvalType">
            <!-- <van-icon size="15" name="fire" color="red" /> -->
            <span class="approvalText">极速审批</span>
          </div>
        </div>
        <!-- 所属银行 -->
        <!-- <div class="bank">中国工商银行</div> -->
        <div class="Money">
          <span class="loansMoney">{{ propsList.priceSection }}</span>
          <span class="txt">万元</span>
        </div>
        <div class="tit">可贷款金额</div>
      </div>
    </div>

    <!-- 标题 -->
    <div class="Title">
      <div class="bigTitle">
        <div class="show">
          <div class="line"></div>
          <span>产品详情</span>
          <div class="line"></div>
        </div>
        <div class="letters">OVERVIEW</div>
      </div>
    </div>

    <!-- 贷款的详细信息 -->
    <div class="loanDetails">
      <div class="innerBox">
        <div class="msgBox">
          <div class="title">贷款额度</div>
          <div class="tit">{{ propsList.newMoney }}元</div>
        </div>
        <div class="msgBox">
          <div class="title">利率</div>
          <div class="tit">年利率{{ propsList.maxRate }}%起</div>
        </div>
        <div class="msgBox">
          <div class="title">分期付款</div>
          <div class="tit">
            {{ propsList.minTime }}-{{ propsList.maxTime }}个月
          </div>
        </div>
        <div class="msgBox">
          <div class="title">贷款方式</div>
          <div class="tit">{{ propsList.creditType }}</div>
        </div>
        <div class="msgBox">
          <div class="title">还款方式</div>
          <div class="tit">{{ propsList.repayType }}</div>
        </div>
      </div>
    </div>

    <!-- 产品介绍 -->
    <div class="productIntroduction">
      <div class="innerBox">
        <span class="title">产品介绍</span>
        <div class="content">
          {{ propsList.introduction }}
        </div>
      </div>
    </div>

    <!-- 服务对象 -->
    <div class="serviceObject">
      <div class="innerBox">
        <span class="title">服务对象</span>
        <div class="content">
          {{ propsList.serviceUser }}
        </div>
      </div>
    </div>
    <div>

    </div>

    <!-- 立即申请 -->
    <div class="btn">
      <div class="innerBox">
        <button @click="apply(propsList)">立即申请</button>
      </div>
    </div>

    <!-- 空标签 -->
    <div class="empty"></div>
  </div>
</template>

<!-- <script
  type="text/javascript"
  src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"
></script> -->
<script>
  import Vue from 'vue';
  import { DetailsFinancialProducts, repayType } from "@/api/RuralFinance";
  import { Toast } from 'vant';
  Vue.use(Toast);
  export default {
    name: "wxf",
    data() {
      return {
        param1: "",
        param2: "",
        obj: {},
        propsList: [],
        identityObj: {},
        identityObjList: []
      };
    },
    mounted() {
      //隐藏头部和底部
      const header = document.querySelector('.header'); // 修改选择器以匹配你的需要
      // const outerElement = header.parentElement;
      // const outerClassName = outerElement.className;
      const noShow = document.querySelector('.noShow')
      const homeManage = document.querySelector('.home-manage')
      const footer = document.querySelector('.footer');

      header.style = "display:none"
      footer.style = "display:none"
      noShow.style = "display:none"
      homeManage.style = "padding-top:0"
      this.initParams();
      if (typeof wx !== "undefined" && wx.miniProgram) {
        this.initWx();
      }
    },
    methods: {
      initParams() {
        const queryParams = this.$route.query;
        console.log(queryParams);

        this.obj = queryParams;
        this.param1 = JSON.parse(queryParams.financeProductId);
        this.identityObj = queryParams.identityObj;
        this.identityObjList = this.$route.query
        console.log(this.param1);

        // console.log(this.param2);
        this.onLoad(this.param1);
      },
      initWx() {
        wx.config({
          debug: false,
          appId: "wx1b4a19d2537a3306",
          timestamp: Math.floor(Date.now() / 1000),
          nonceStr: Math.random().toString(36).substr(2, 15),
          signature: signature, // 确保 signature 正确
          jsApiList: ["scanQRCode", "chooseImage", "navigateTo"],
        });

        wx.ready(() => {
          console.log("WeChat SDK is ready.");
        });
      },
      handleNavigation() {
        if (typeof wx !== "undefined" && wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: "/pages/Publish/Publish",
          });
        } else {
          console.warn("Not in WeChat Mini Program environment.");
        }
      },

      async onLoad(options) {
        // 贷款详情
        let params = {
          financeProductId: options.financeProductId,
        };
        let resData = await DetailsFinancialProducts(params);
        if (resData.data[0]) {
          this.obj = resData.data[0];
        } else {
          this.$router.push({ name: 'xctfw', query: { queryParams: '' } })
        }

        // this.propsList = resData.data[0];
        let propsList1 = resData.data[0];
        propsList1.introduction = propsList1.introduction
          ? propsList1.introduction
          : "暂无数据";
        propsList1.serviceUser = propsList1.serviceUser
          ? propsList1.serviceUser
          : "暂无数据";
        // console.log(propsList.serviceUser, '查看有这个数值嘛')
        // propsList.serviceUser = propsList.split('↵').join('\n');
        // 还款方式
        let res = await repayType();
        let TorRepayType = res.data;
        // let propsList = newList
        let arr2 = Array.from(propsList1.repayType.split(","));
        let repay_Type = "";
        for (let i = 0; i < TorRepayType.length; i++) {
          if (arr2.indexOf(TorRepayType[i].value) > -1) {
            repay_Type += TorRepayType[i].description + " ";
          }
        }
        propsList1.repayType = repay_Type;

        //贷款方式
        let params2 = "credit_type";
        let loanData = await repayType(params2);
        let ToGuaranteeMethods = loanData.data;
        let arr = Array.from(propsList1.creditType.split(","));
        let credit_type_name = "";
        for (let i = 0; i < ToGuaranteeMethods.length; i++) {
          if (arr.indexOf(ToGuaranteeMethods[i].value) > -1) {
            credit_type_name += ToGuaranteeMethods[i].description + " ";
          }
        }
        propsList1.creditType = credit_type_name;

        //贷款额度改变样式
        let newMoney = propsList1.minMoney + " ~ " + propsList1.maxMoney;
        propsList1.newMoney = newMoney;
        //改变图片样式
        // propsList1.logoUrl = `${app.globalData.imgurl}/admin/file/get?ossFilePath=${propsList1.logoUrl}`;

        // console.log(propsList, "查看newData");
        // 可贷款金额
        const newMinMoney = propsList1.minMoney / 10000;
        // item.minMoney = newMinMoney;
        const newMaxMoney = propsList1.maxMoney / 10000;
        const priceSection = newMinMoney + "~" + newMaxMoney;
        propsList1.priceSection = priceSection;
        this.propsList = propsList1;
      },
      apply() {
        console.log(this.identityObjList);

        if (JSON.parse(this.identityObj).identityTypeCode) {
          this.$router.push({ name: 'dksq', query: { queryParams: this.identityObjList } })
        } else {
          Toast('您还未登录，即将跳转登录页');
          setTimeout(() => {
            this.wx.miniProgram.navigateTo({ url: '/pagesB/login/login' })
          }, 1000)
        }
      }
    },
  };
</script>
<style lang="less" scoped>
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: "Source Han Sans CN", sans-serif;
  }

  div {
    box-sizing: border-box;
  }


  /* 贷款详细信息 */
  .loanDetail {
    text-align: center;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 60vh;
    padding: 20px;

    .basicInformation {
      width: 100%;
      display: flex;
      background: white;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .Image {
        margin-right: 20px;

        img {
          width: 110px;
          height: 110px;
          border-radius: 10px;
        }

        .img {
          border: solid 1px black;
          border-radius: 15px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;

        .loansTitle {
          display: flex;
          align-items: center;

          .title {
            font-weight: 400;
            font-size: 22px;
            color: #000000;
          }

          .approvalType {
            margin-left: 20px;
            padding: 10px 20px;
            background-color: #f3eadb;
            border-radius: 12px;
            font-weight: 400;
            font-size: 16px;
            color: #a08926;
          }
        }

        .Money {
          margin-top: 20px;
          font-weight: bold;
          font-size: 33px;
          color: #d25731;
        }

        .bank {
          margin-top: 30px;
          font-size: 16px;
          color: #666666;
        }

        .tit {
          margin: 13px 0;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
        }

        .txt {
          color: #d25731;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }

  /* 标题 */
  .Title {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;

    .bigTitle {
      display: flex;
      flex-direction: column;
      align-items: center;

      .show {
        font-size: 36px;
        font-weight: bold;
        display: flex;
        align-items: center;

        .line {
          margin: 0 10px;
          width: 50px;
          border-bottom: solid 2px #aaaaaa;
        }
      }

      .letters {
        font-size: 22px;
        color: #acacac;
      }
    }
  }

  /* 贷款详细信息 */
  .loanDetails {
    width: 100%;
    background: white;
    display: flex;
    justify-content: center;
    padding: 40px 0;

    .innerBox {
      width: 90%;
      max-width: 1200px;

      .msgBox {
        width: 100%;
        display: flex;
        padding-bottom: 39px;
        justify-content: space-between;

        .title {
          color: #999999;
          display: flex;
          align-items: center;
          font-size: 18px;
        }

        .tit {
          width: 75%;
          font-size: 16px;
          text-align: right;
        }
      }
    }
  }

  /* 产品介绍 */
  .productIntroduction {
    width: 100%;
    margin-top: 20px;
    background: white;
    display: flex;
    justify-content: center;
    padding: 40px 0;

    .innerBox {
      width: 100%;
      max-width: 1200px;

      .title {
        font-size: 22px;
        font-weight: bold;
        color: #a08926;
      }

      .content {
        padding-top: 29px;
        font-size: 14px;
        color: #333;
      }
    }
  }

  /* 服务对象 */
  .serviceObject {
    width: 100%;
    padding-top: 52px;
    background: white;
    display: flex;
    justify-content: center;
    padding-bottom: 22px;

    .innerBox {
      width: 90%;
      max-width: 1200px;

      .title {
        font-size: 22px;
        font-weight: bold;
        color: #a08926;
      }

      .content {
        padding-top: 29px;
        font-size: 14px;
        color: #333;
      }
    }
  }

  /* 立即申请 */
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #ffffff;

    .innerBox {
      width: 670px;
      height: 79px;
      display: flex;
      justify-content: center;

      button {
        width: 100vw;
        max-width: 561px;
        background: black;
        color: white;
        border: none;
        border-radius: 40px;
        padding: 15px;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }

  /* 空标签 */
  .empty {
    width: 100%;
    height: 150px;
    background: #ffffff;
  }

  /* 响应式设计 */
  @media (max-width: 760px) {

    .loanDetail {
      width: 100vw;

      height: auto;
      padding: 10px;
      font-size: 0.5rem;
    }

    .basicInformation {
      flex-direction: column;
      align-items: center;
      font-size: 0.5rem;
    }

    .Image {
      margin-right: 0;
      margin-bottom: 10px;
      font-size: 0.5rem;
    }

    .content {
      margin-left: 0;
      text-align: center;
      font-size: 0.5rem;
    }

    .btn .innerBox {
      width: 100vw;
      height: auto;
      font-size: 0.5rem;
    }

    .tit {
      font-size: 0.5rem;
    }

    button {
      font-size: 0.5rem;
      padding: 10px;
    }
  }
</style>